<template>
  <topbar
    :titulo="$t('FINANCEIRO.INTEGRACAO_FINANCEIRA_CONTABIL')"
    :subtitulo="$t('FINANCEIRO.INTEGRACAO_FINANCEIRA_CONTABIL_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  />
</template>
  <script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
        { titulo: this.$t('FINANCEIRO_LANCAMENTOS.PAGE_TITLE') },
        { titulo: this.$t('FINANCEIRO.INTEGRACAO_FINANCEIRA_CONTABIL') },
      ],
    };
  },
};
</script>
              