import ApiService from '@/common/api/api.service';

const urlBaseContaPagar = 'IntegracaoFinanceiraContabil';

const IntegracaoFinanceiraContabilService = {
    async criar(form) {
        form.TipoConformeConta = form.tipoConformeContaId
        form.TipoValor = form.tipoValorId
        let result = await ApiService.post(`${urlBaseContaPagar}`, form);
        return result;
    },
    async editar(form) {
        form.TipoConformeConta = form.tipoConformeContaId
        form.TipoValor = form.tipoValorId
        let result = await ApiService.post(`${urlBaseContaPagar}/atualizar`, form);
        return result;
    },
    async excluir(itemId) {
        let result = await ApiService.put(`${urlBaseContaPagar}/${itemId}`);
        return result;
    },
    async obterPor(filtro) {
        let { data } = await ApiService.get(`${urlBaseContaPagar}`, {
            params: {
                HistoricoLancamentoId: filtro.historicoLancamentoId,
                TipoHistorico: filtro.tipoHistorico
            }
        });
        return data;
    },
    async listarParametros() {
        let { data } = await ApiService.get(`${urlBaseContaPagar}/obter-integracao-parametro`);
        return data;
    }
};

export default IntegracaoFinanceiraContabilService;
