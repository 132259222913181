<template>
  <b-table
    :items="items"
    :fields="fields"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="data">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="duplicar(data.item)">
          Duplicar
        </b-dropdown-item>
        <b-dropdown-item @click="editar(data.item)"> Editar </b-dropdown-item>
        <b-dropdown-item @click="excluir(data.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <template #cell(aplicaDescontoTaxa)="data">
      {{ data.item.aplicaDescontoTaxa ? 'Sim' : 'Não' }}
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import IntegracaoFinanceiraContabilService from '@/common/services/integracao-financeira-contabil/integracao-financeira-contabil.service.js';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricaoParametro',
          label: 'Parâmetro',
          sortable: true,
        },
        {
          key: 'tipoConformeContaDescricao',
          label: 'Tipo de Conta',
          sortable: true,
        },
        {
          key: 'planoConta.descricao',
          label: 'Plano Conta',
          sortable: true,
        },
        {
          key: 'percentualRateio',
          label: 'Rateio (%)',
          sortable: true,
        },
        {
          key: 'aplicaDescontoTaxa',
          label: 'Desconto/Taxa aplicada?',
          sortable: true,
        },
        {
          key: 'tipoValorDescricao',
          label: 'Tipo de Valor',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    duplicar(item) {
      var copyItem = Object.assign({}, item);
      copyItem.id = null;
      copyItem.aplicaDescontoTaxa = null;
      copyItem.percentualRateio = null;
      copyItem.tipoValorId = null;
      copyItem.tipoConformeContaId = null;
      copyItem.planoContaId = null;
      this.integracaoContabil(copyItem);
    },
    editar(item) {
      this.integracaoContabil(item);
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      IntegracaoFinanceiraContabilService.excluir(item.id)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('recarregarDadosFiltro');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    integracaoContabil(item) {
      this.$emit('integracaoContabil', item);
    },
  },
};
</script>
