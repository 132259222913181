<template>
  <!--
		TODO:
		- transformar isso em um modal step by step
		- verificar dados de alimentação back-end
	-->
  <div class="row">
    <div class="col-12 col-md-6">
      <input-select-search
        v-model="form.historicoLancamentoId"
        label="FINANCEIRO.HISTORICO_LANCAMENTO"
        :options="opcoes.historicosLancamento"
        required
      />
    </div>

    <!-- TODO: verificar back-end onde chama esse dado -->
    <div class="col-12 col-md-6">
      <input-select-search
        v-model="form.integracaoParametroFinanceiraContabilId"
        label="FINANCEIRO.INTEGRACAO_PARAMETRO_FINANCEIRO_CONTABIL"
        :options="opcoes.integracaoParametros"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        v-model="form.planoContaId"
        label="FINANCEIRO.PLANO_CONTA"
        :options="opcoes.planoContas"
        required
      />
    </div>

    <div class="col-12 col-md-4">
      <input-select-search
        v-model="form.tipoConformeContaId"
        label="FINANCEIRO.TIPO_CONTA"
        :options="opcoes.tipoContas"
        required
      />
    </div>

    <div class="col-12 col-md-6">
      <input-select-search
        v-model="form.tipoValorId"
        label="FINANCEIRO.TIPO_VALOR"
        :options="opcoes.tipoValores"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-number
        v-model="form.percentualRateio"
        label="FINANCEIRO.PERCENTUAL_RATEIO"
        required
        decimal
      />
    </div>
    <div class="col-12 col-md-4">
      <input-checkbox
        v-model="form.aplicaDescontoTaxa"
        :label="$t('FINANCEIRO.APLICAR_DESCONTO_TAXA')"
        :placeholder="$t('GERAL.SOMENTE_NUMEROS')"
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';
import PlanoContaService from '@/common/services/plano-conta/plano-conta.service';
import IntegracaoFinanceiraContabilService from '@/common/services/integracao-financeira-contabil/integracao-financeira-contabil.service.js';

// Components:
import {
  InputCheckbox,
  InputNumber,
  InputSelectSearch,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputCheckbox,
    InputNumber,
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        planoContas: [],
        historicosLancamento: [],
        integracaoParametros: [],
        tipoContas: [],
        tipoValores: [],
      },
      paginacao: {
        paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 5 },
        total: 0,
      },
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getPlanoContas();
    this.getHistoricoLancamento();
    this.getIntegracaoParametros();
    this.getTipoConta();
    this.getTipoValor();
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getPlanoContas() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.obterTodos()
        .then(({ data }) => {
          this.opcoes.planoContas = data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getHistoricoLancamento() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterTodosHistoricosLancamentosSemPaginacao()
        .then(({ data }) => {
          this.opcoes.historicosLancamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getIntegracaoParametros() {
      this.$store.dispatch(START_LOADING);
      IntegracaoFinanceiraContabilService.listarParametros()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.integracaoParametros = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoConta() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-conforme-contas')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoContas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoValor() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-valor-integracao')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoValores = data;
        })
        .catch((err) => {
          mensagem.erro(this.$t('GERAL.ERRO'), err.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
