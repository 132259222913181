<template>
  <div>
    <titulo-lancamento-integracao-financeiro-contabil />
    
    <filtro-integracao @filtrar-por="receberDadosFiltro" />

    <tabela-integracao
      @recarregarDadosFiltro="recarregarDadosFiltro"
      :items="items"
      @integracaoContabil="abrirModalIntegracaoContabil"
    />

    <modal-integracao
      :exibir="modais.integracao"
      :dados="modais.integracaoDados"
      @fechar="closeModal"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import IntegracaoFinanceiraContabilService from '@/common/services/integracao-financeira-contabil/integracao-financeira-contabil.service.js';

// Components:
import TabelaIntegracao from './components/Tabela';
import FiltroIntegracao from './components/Filtro';
import ModalIntegracao from './components/modal/Index';
import TituloLancamentoIntegracaoFinanceiroContabil from '@/views/financeiro/lancamentos/integracao-contabil/components/TituloLancamentoIntegracaoFinanceiroContabil';

export default {
  components: {
    TituloLancamentoIntegracaoFinanceiroContabil,
    FiltroIntegracao,
    TabelaIntegracao,
    ModalIntegracao,
  },
  data() {
    return {
      items: [],
      filtradoPor: {},
      modais: {
        integracao: false,
        integracaoDados: {},
      },
    };
  },
  methods: {
    // FUNÇÕES FILTRO:
    receberDadosFiltro(resultado) {
      this.filtradoPor = resultado;
      this.$store.dispatch(START_LOADING);
      IntegracaoFinanceiraContabilService.obterPor(this.filtradoPor)
        .then(({ data }) => {
          this.items = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    recarregarDadosFiltro() {
      this.$store.dispatch(START_LOADING);
      IntegracaoFinanceiraContabilService.obterPor(this.filtradoPor)
        .then(({ data }) => {
          this.items = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal() {
      this.modais.integracao = false;
      this.recarregarDadosFiltro();
    },
    abrirModalIntegracaoContabil(item) {
      item.historicoLancamentoId = this.filtradoPor.historicoLancamentoId;
      this.modais.integracaoDados = item;
      this.modais.integracao = true;
    },
  },
};
</script>
