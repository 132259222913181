<template>
  <div class="row">
    <b-col cols="12" md="4">
      <input-select-search
        ref="tipoHistorico"
        v-model="selecionado.tipoHistorico"
        label="FINANCEIRO.TIPO_HISTORICO"
        :options="opcoes.tiposHistorico"
        required
      />
    </b-col>
    <b-col cols="12" md="4">
      <input-select-search
        v-model="selecionado.historicoLancamentoId"
        label="FINANCEIRO.HISTORICO_LANCAMENTO"
        :options="opcoes.historicosLancamento"
        required
      />
    </b-col>
    <b-col cols="12" md="auto" class="mt-3 mt-md-0">
      <b-button
        class="botao-acao-filtro w-100"
        variant="primary"
        @click="emitirDados"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>
    <b-col cols="12" md="auto" class="mt-3 mt-md-0">
      <b-button
        class="botao-acao-filtro mb-3 w-100"
        variant="secondary"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      selecionado: {
        historicoLancamentoId: '',
        tipoHistorico: '',
      },
      opcoes: {
        historicosLancamento: [],
        tiposHistorico: [],
      },
    };
  },
  mounted() {
    this.getTipoHistorico();
    this.getHistoricoLancamentos();
  },
  watch: {
    'selecionado.tipoHistorico': {
      handler(value) {
        this.selecionado.tipoHistorico = value;
        this.getHistoricoLancamentoPorTipo();
      },
      deep: true,
    },
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getHistoricoLancamentos() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterEnumContasPagar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.historicosLancamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoHistorico() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('historico-financeiro')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tiposHistorico = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getHistoricoLancamentoPorTipo() {
      if (this.selecionado.tipoHistorico.length === 0) {
        return;
      }
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterHistoricoLancamentoPorTipo(
        this.selecionado.tipoHistorico
      )
        .then(({ data }) => {
          this.opcoes.historicosLancamento = [];

          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.historicosLancamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DE EMISSÃO DE DADOS:
    emitirDados() {
      this.$emit('filtrar-por', this.selecionado);
    },
    // FUNÇÕES DO FILTRO:
    limparFiltro() {
      Object.keys(this.selecionado).forEach((item) => {
        this.selecionado[item] = '';
      });
    },
  },
};
</script>
